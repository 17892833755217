import { useStyles } from '../hooks/useStyles';
import { FullContainer } from '../ui/FullContainer';

import development from '../../assets/img/development.gif';
import developmentMobile from '../../assets/img/development_mobile.gif';

export function MaintenancePage() {
  const { classes, concatenateClasses, css } = useStyles();
  const imageStyles = css({
    width: '100%',
    maxHeight: '90vh',
  });

  return (
    <FullContainer className={concatenateClasses(classes.flexCenter, classes.backgroundAccent)}>
      <div className={css({ padding: '0 5%' })}>
        <img src={development} alt="..." className={concatenateClasses(imageStyles, classes.visibleMd)} />
        <img src={developmentMobile} alt="..." className={concatenateClasses(imageStyles, classes.hiddenMd)} />
      </div>
    </FullContainer>
  );
}

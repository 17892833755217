import { useStyletron } from 'styletron-react';

export function useStyles() {
  const [css] = useStyletron();
  const accentColor = '#f5d207';

  const breakpoints = {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  };

  const mediaQueries = {
    xs: `@media screen and (min-width: ${breakpoints.xs})`,
    sm: `@media screen and (min-width: ${breakpoints.sm})`,
    md: `@media screen and (min-width: ${breakpoints.md})`,
    lg: `@media screen and (min-width: ${breakpoints.lg})`,
    xl: `@media screen and (min-width: ${breakpoints.xl})`,
    xxl: `@media screen and (min-width: ${breakpoints.xxl})`,
  };

  const imgResponsive = css({
    maxWidth: '100%',
  });

  const flexColumn = css({
    display: 'flex',
    flexDirection: 'column',
  });

  const flexRow = css({
    display: 'flex',
  });

  const hiddenMd = css({
    display: 'block',
    [mediaQueries.md]: {
      display: 'none',
    },
  });

  const visibleMd = css({
    display: 'none',
    [mediaQueries.md]: {
      display: 'block',
    },
  });

  const flexCenter = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const backgroundAccent = css({
    backgroundColor: accentColor,
  });

  const concatenateClasses = (...classes: string[]) => {
    return classes.join(' ');
  };

  return {
    breakpoints,
    css,
    concatenateClasses,
    mediaQueries,
    classes: {
      imgResponsive,
      flexColumn,
      flexRow,
      visibleMd,
      hiddenMd,
      flexCenter,
      backgroundAccent,
    },
  };
}
